import axios, { AxiosError, AxiosResponse } from "axios";
import { useEffect } from "react";

const AxiosInterceptor = ({ children }: any) => {
  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => {
      return response;
    };
    const errInterceptor = (error: AxiosError) => {
      if (error?.response?.status === 401) {
        localStorage.clear();
      }
      return Promise.reject(error);
    };
    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => axios.interceptors.response.eject(interceptor);
  }, []);
  return children;
};

export default axios;
export { AxiosInterceptor };
