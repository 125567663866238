import { LOADING } from "../auth/authType";
import {
  GET_ONE_EMPLOYEE_SALARY,
  GET_ALL_EMPLOYEE_SALARY,
} from "./emplSalaryType";

const initialState: any = {
  data: [],
  oneUser: [],
  loading: false,
};

export default function holidayReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ALL_EMPLOYEE_SALARY:
      return {
        ...state,
        data: action.payload,
      };
    case GET_ONE_EMPLOYEE_SALARY:
      return {
        ...state,
        oneUser: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
