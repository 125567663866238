import {
  GET_TAG_DATA,
  GET_TAG_DATA_By_Id,
  LOADING,
} from "./tagType";

const initialState: any = {
  data: [],
  loading: false,
};

export default function tagReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_TAG_DATA:
      return {
        ...state,
        data: action.payload,
      };
      case  GET_TAG_DATA_By_Id:
      return {
        ...state,
        data: action.payload,
      };

    case LOADING : 
    return {
      ...state,
      loading: action.playload
    };

    default:
      return state;
  }
}
