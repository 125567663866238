import { GET_ALL_PROJECT, LOADING } from "./projectType";

const initialState: any = {
    data: [],
    loading: false,
};

export default function projectReducer(state = initialState, action: any) {

    switch (action.type) {
        case GET_ALL_PROJECT:
            return {
                ...state,
                data: action.payload?.list
            }
        case LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state
    };
}