import { GET_CLIENTS_DATA, LOADING } from "./clientType";

const initialState: any = {
        loading: false,
        data: [],
};

export default function projectReducer(state = initialState, action: any) {

    switch (action.type) {
        case GET_CLIENTS_DATA:
            return {
                ...state,
                data : action.payload
            }
        case LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    };

}