import { GET_LEAVES_DATA, LOADING } from "./leaveType";

const initialState: any = {
  data: [],
  loading: false,
};

export default function leaveReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_LEAVES_DATA: {
      return {
        ...state,
        data: action.payload?.data,
      };
    }
    case LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
}
