import {
  GET_ALL_USER,
  GET_SPECIFIC_VIEW_USER,
  GET_USER_LOG_DATE,
  LOADING,
} from "./userLogType";

const initialState: any = {
  // count: "",
  data: [],
  dataOfSpecificUser: {},
  loading: false,
  userViewLogByDate: new Date(),
  // user: [],
  // attendance: null,
};

export default function userLogManagement(state = initialState, action: any) {
  switch (action.type) {
    case GET_ALL_USER:
      return {
        ...state,
        // count: action.payload?.count,
        // users: action.payload?.users,
        data: action.payload,
      };
    //Tarun Changes
    case GET_SPECIFIC_VIEW_USER:
      return {
        ...state,
        dataOfSpecificUser: action.payload,
      };
    case GET_USER_LOG_DATE:
      return {
        ...state,
        userViewLogByDate: action.payload,
      };

    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
