import { GET_ALL_ORG, GET_ONE_ORG, LOADING } from "./organizationType";

const initialState: any = {
  data: [],
  orgData: null,
  loading: false,
};

export default function OrganizationReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ALL_ORG:
      return {
        ...state,
        data: action.payload,
      };

    case GET_ONE_ORG: {
      let data = {};
      Object.keys(action.payload).map((index) => {
        data = action.payload[index];
      });
      return {
        ...state,
        data: data,
      };
    }

    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
