import { LOADING } from "../auth/authType";
import { GET_ALL_HOLIDAY } from "./holidayType";

const initialState: any = {
  data: [],
  loading: false,
};

export default function holidayReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case GET_ALL_HOLIDAY:
      return {
        ...state,
        data: action.payload?.data,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
