import { LOADING } from "../auth/authType";
import {
  GET_ALL_USER,
  GET_ONE_USER,
  USER_ATTENDANCE,
  USER_LOGS,
} from "./userType";

const initialState: any = {
  // count: "",
  data: [],
  loading: false,
  userLogs: [],
  // user: [],
  // attendance: null,
};

export default function userManagementReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case GET_ALL_USER:
      return {
        ...state,
        // count: action.payload?.count,
        // users: action.payload?.users,
        data: action?.payload,
      };
    case GET_ONE_USER: {
      let data = {};
      Object.keys(action.payload)?.map((index) => {
        data = action?.payload[index];
      });
      return {
        ...state,
        // user: data,
        data: data,
      };
    }
    case USER_ATTENDANCE: {
      return {
        ...state,
        // attendance: action.payload,
        data: action.payload,
      };
    }
    case USER_LOGS: {
      return {
        ...state,
        userLogs: action.payload,
      };
    }
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
