// let serviceUrl = process.env.REACT_APP_SERVICE_URL;
const BASE_URL = process.env.REACT_APP_SERVICE_URL;

export const API_URL = {
  auth: {
    login: `${BASE_URL}/api/auth/login`,
    logout: `${BASE_URL}/api/auth/logout`,
    tokenUser: `${BASE_URL}/api/auth/getUserByToken`,
    otpEmail: `${BASE_URL}/api/users/sendOTP`,
    forgotPass: `${BASE_URL}/api/users/forgatePassword`,
  },
  userCheck: {
    checkIn: `${BASE_URL}/api/usercheck/checkIn`,
    checkOut: `${BASE_URL}/api/usercheck/checkOut`,
    breakIn: `${BASE_URL}/api/usercheck/breakIn`,
    breakout: `${BASE_URL}/api/usercheck/breakOut`,
    userLogDetails: `${BASE_URL}/api/usercheck/users_logsDetails`,
    userCheck: `${BASE_URL}/api/usercheck/checkStatus`,
    deleteAllLogs: `${BASE_URL}/api/usercheck/deleteAllLogsForUser`,
    logsDateWise: `${BASE_URL}/api/usercheck/logsDateWise`,
    logsDetails: `${BASE_URL}/api/usercheck/users_logsDetails`,
    newLogAdd: `${BASE_URL}/api/newlog/createNewLog`,
    newLogListAdmin: (page: any, perPage: any, date: any) =>
      `${BASE_URL}/api/newlog/pendingRequest?page=${page}&pageSize=${perPage}&date=${date}`,
    logApproveAdmin: `${BASE_URL}/api/newlog/transferlog`,
    userOldLogShow: (id: string) => `${BASE_URL}/api/newlog/getOldLogs/${id}`,
    userRequest: (id: string) => `${BASE_URL}/api/newlog/GetNewLogs/${id}`,
    userGetAllChangesLog: (id: string) => `${BASE_URL}/api/newlog/GetAllLogChangeRequest/${id}`,
    userRequestDelete: (id: string, dataId: any) =>
      `${BASE_URL}/api/newlog/deleteNewLogs/${id}?_id=${dataId}`,
    userOldLogShowDataFetch: (id: any, date) =>
      `${BASE_URL}/api/newlog/RestoreLogs/${id}?date=${date}`,
  },
  addUser: {
    addOneUser: `${BASE_URL}/api/users/signup`,
    getAllUser: `${BASE_URL}/api/users`,
    getUserRole: `${BASE_URL}/api/userRole`,
    getOneUser: `${BASE_URL}/api/users`,
    usreProflieUpdate: `${BASE_URL}/api/users`,
    EditOneUser: `${BASE_URL}/api/users/updateSingupData`,
    DeleteOneUser: `${BASE_URL}/api/users`,
    usrePasswordChange: `${BASE_URL}/api/users/changePassword`,
    profileUpload: `${BASE_URL}/api/users/profileUpload`,
    updateuserLogs: `${BASE_URL}/api/usercheck/checkLogsUpdate`,
    userAttandance: `${BASE_URL}/api/usercheck/chackAttendance`,
    userGetAttendance: `${BASE_URL}/api/usercheck/getAttendance`,
  },
  leave: {
    getAdminLeaves: `${BASE_URL}/api/userLeave/`,
    getUserLeaves: `${BASE_URL}/api/userLeave`,
    addLeaves: `${BASE_URL}/api/userLeave`,
    editLeaves: `${BASE_URL}/api/userLeave/leaveUpdate`,
    deleteLeaves: `${BASE_URL}/api/userLeave/leaveDelete`,
    approveLeaves: `${BASE_URL}/api/userLeave`,
  },
  holiday: {
    deleteHoliday: `${BASE_URL}/api/holiday/deleteHoliday`,
    updateHoliday: `${BASE_URL}/api/holiday/updateHoliday`,
    getAllHolidays: `${BASE_URL}/api/holiday/getAllHolidays`,
    getOneHolidays: `${BASE_URL}/api/holiday/getAllHolidays`,
    addOneHoliday: `${BASE_URL}/api/holiday`,
    userAllEvents: `${BASE_URL}/api/users/userEvents`,
  },
  project: {
    getAllProjects: `${BASE_URL}/api/project/`,
    addOneProject: `${BASE_URL}/api/project/`,
    editOneProject: `${BASE_URL}/api/project`,
    deleteOneProject: `${BASE_URL}/api/project`,
  },
  clients: {
    getAllClients: `${BASE_URL}/api/client/`,
  },
  tag: {
    getAllTag: `${BASE_URL}/api/tags`,
    getAllTagById: `${BASE_URL}/api/tags`,
    createTag: `${BASE_URL}/api/tags`,
    deleteTag: `${BASE_URL}/api/tags`,
    editOneTag: `${BASE_URL}/api/tags`,
  },
  toggle: {
    createToggle: `${BASE_URL}/api/toggle`,
    getAllToggle: `${BASE_URL}/api/toggle/getAll`,
    getByUserIdToggle: `${BASE_URL}/api/toggle`,
    DeleteToggle: `${BASE_URL}/api/toggle`,
    EditToggleData: `${BASE_URL}/api/toggle`,
  },
  extrastaffing: {
    getAllExtraStaffing: `${BASE_URL}/api/extra-staffing/getAllExtra_Staffing`,
    getOneUserExtraStaffing: (userId) =>
      `${BASE_URL}/api/extra-staffing/getOneUser/${userId}`,
    addExtraStaffing: `${BASE_URL}/api/extra-staffing`,
    editExtraStaffing: `${BASE_URL}/api/extra-staffing/updateUser`,
    deleteExtraStaffing: `${BASE_URL}/api/extra-staffing/delete`,
    approveExtraStaffing: `${BASE_URL}/api/extra-staffing/isApprove_extraStaffing`,
  },
  emplsalary: {
    addEmplSalary: `${BASE_URL}/api/empsalary`,
    updateEmplSalary: `${BASE_URL}/api/empsalary`,
    getAllEmplSalary: `${BASE_URL}/api/empsalary/allUserSalary`,
    getOneEmplSalary: `${BASE_URL}/api/empsalary/UserSalaryById`,
    activeSalary: `${BASE_URL}/api/empsalary/markActive`,
    deleteSalary: `${BASE_URL}/api/empsalary/deleteSalary`,
    userMonthlySalary: (id) =>
      `${BASE_URL}/api/empsalary/monthlyUserSalary/${id}`,
    userSalaryPaidStatus: `${BASE_URL}/api/empSalaryPaidStatus/create`,
    salaryStatusGetByUserId: `${BASE_URL}/api/empSalaryPaidStatus/GetUserSalaryStatus`,
    userSalaryStatusUpdate:(recordId) => `${BASE_URL}/api/empSalaryPaidStatus/UpdateSalaryStatus/${recordId}`,
    getBreakRequest: `${BASE_URL}/api/empBreakRequest/getBreakRequest`,
    createBreakRequest: `${BASE_URL}/api/empBreakRequest/createBreakRequest`,
    updateBreakRequest: (id) => `${BASE_URL}/api/empBreakRequest/updateBreakRequest/${id}`,
    deleteBreakRequest: (id) => `${BASE_URL}/api/empBreakRequest/deleteBreakRequest/${id}`,
    getOneUserBreakRequest: `${BASE_URL}/api/empBreakRequest/getUserBreakRequest`,
  },
  organization: {
    getAllListOrg: `${BASE_URL}/api/organization`,
    AddOrg: `${BASE_URL}/api/organization`,
    updateOrg: `${BASE_URL}/api/organization`,
    deleteOrg: `${BASE_URL}/api/organization`,
    getOneOrg: `${BASE_URL}/api/organization`,
  },
  projectcatalogue: {
    getByProjectId: `${BASE_URL}/api/project-catalogue`,
    getAllProjectCatalogue: `${BASE_URL}/api/project-catalogue/`,
    addProjectCatalogue: `${BASE_URL}/api/project-catalogue/`,
    deleteProjectCatalogue: `${BASE_URL}/api/project-catalogue`,
    editPRojectCatalogue: `${BASE_URL}/api/project-catalogue/update`,
  },
  userLogSettings: {
    getLogSettings: `${BASE_URL}/api/daysetting/getSetting`,
    deleteLogSetting: `${BASE_URL}/api/daysetting/deleteSetting`,
    UpdateLogSetting: `${BASE_URL}/api/daysetting/updateSetting`,
    createLogSetting: `${BASE_URL}/api/daysetting/createSetting`,
  },
  metaNeoverce: {
    getMetaTag: `${BASE_URL}/api/meta-info/get-metadata`,
    deleteMetaTag: (id) => `${BASE_URL}/api/meta-info/delete-metaInfo/${id}`,
    UpdateMetaTag: (id) => `${BASE_URL}/api/meta-info/update-metaInfo/${id}`,
    createMetaTag: `${BASE_URL}/api/meta-info/create-metaInfo`,
  },
  paidLeaves: {
    getPaidRequest:(page,limit) =>`${BASE_URL}/api/paidLeave/getPaidLeaveRequest?page=${page}&limit=${limit}`,
    createPaidRequest: `${BASE_URL}/api/paidLeave/createPaidLeaveRequest`,
    updatePaidRequest: (id) => `${BASE_URL}/api/paidLeave/updatePaidLeaveRequest/${id}`,
    deletePaidRequest: (id) => `${BASE_URL}/api/paidLeave/deletePaidLeaveRequest/${id}`,
    userUpdatePaidLeaves: `${BASE_URL}/api/paidLeave/UserUpdatePaidLeaveRequest`,
    getOneUserPaidLeavesRequest:  `${BASE_URL}/api/paidLeave/getUserPaidLeaveRequest`,
  },
};
