import Route from "./route";
import { BrowserRouter as Router } from "react-router-dom";
import { AxiosInterceptor } from "./api/AxiosInterceptor";
import configureStore from "./store";
import { Provider } from "react-redux";
import "react-dropzone-uploader/dist/styles.css";
import AuthGuard from "./api/AuthGuard";
import { ToastContainer } from "react-toastify";
import "../src/index.css";
import "react-toastify/dist/ReactToastify.css";

export const App = () => {
  const store = configureStore();
  return (
    <Provider store={store}>
      <Router>
        <AxiosInterceptor>
          <AuthGuard>
            <ToastContainer />
            <Route />
          </AuthGuard>
        </AxiosInterceptor>
      </Router>
    </Provider>
  );
};
