import { GET_EXTRA_STAFFING_DATA, LOADING } from "./ExtraStaffingType";

const initialState: any = {
    data: [],
    loading: false,
};

export default function extraStaffingReducers(state = initialState, action: any) {
    switch (action.type) {
        case GET_EXTRA_STAFFING_DATA: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        default:
            return state;
    }
}
