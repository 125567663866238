import {
  LOGIN_USER,
  LOGOUT_USER,
  LOADING,
  TOKEN_USER_DATA_HANDLER,
  OTPEMAIL,
} from "./authType";
import { API_URL } from "../../api/config";
import { apiClient } from "../../api/general";
import { showToast } from "../../screen/Common/Toaster";

export const login = (data: any) => async (dispatch: any) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: LOADING,
      payload: true,
    });
    apiClient({
      method: "POST",
      url: `${API_URL.auth.login}`,
      data,
    })
      .then((response: any) => {
        resolve(response?.data);
        dispatch({
          type: LOGIN_USER,
          payload: {
            token: response.data.token,
            data: response.data.userdata,
          },
        });
      })
      .catch((error: any) => {
        reject(error);
      })
      .finally(() => {
        dispatch({
          type: LOADING,
          payload: false,
        });
      });
  });
};

export const tokenUserData = (data: any) => async (dispatch: any) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: LOADING,
      payload: true,
    });
    apiClient({
      method: "POST",
      url: `${API_URL.auth.tokenUser}`,
      data,
    })
      .then((response: any) => {
        resolve(response.data);
        dispatch({
          type: TOKEN_USER_DATA_HANDLER,
          payload: response.data,
        });
      })
      .catch((error: any) => {
        reject(error);
      })
      .finally(() => {
        dispatch({
          type: LOADING,
          payload: false,
        });
      });
  });
};

export const logout = (data: { id: string }) => async (dispatch: any) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: `${API_URL.auth.logout}`,
      data,
    })
      .then((response: any) => {
        dispatch({
          type: LOGOUT_USER,
          payload: {},
        });
        resolve(response);
      })
      .catch((error) => {
        dispatch(error.message);
        reject(error);
      });
  });
};

export const sendOtp = (data: any) => async (dispatch: any) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: LOADING,
      payload: true,
    });
    apiClient({
      method: "POST",
      url: `${API_URL.auth.otpEmail}`,
      data,
    })
      .then((response: any) => {
        resolve(response);
        dispatch({
          type: OTPEMAIL,
          payload: data?.email,
        });
      })
      .catch((error) => {
        dispatch(error.message);
        reject(error);
      })
      .finally(() => {
        dispatch({
          type: LOADING,
          payload: false,
        });
      });
  });
};

export const forgatePassword = (data: any) => async (dispatch: any) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: LOADING,
      payload: true,
    });
    apiClient({
      method: "POST",
      url: `${API_URL.auth.forgotPass}`,
      data,
    })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        dispatch(error.message);
        reject(error);
      })
      .finally(() => {
        dispatch({
          type: LOADING,
          payload: false,
        });
      });
  });
};

// export const modeHanlder = (data: any) => async (dispatch: any) => {
//   dispatch({
//     type: MODE_HANDLER,
//     payload: data,
//   });
// };
