import authReducer from "./auth/authReducer";
import drawerReducer from "./drawer/drawerReducer";
import userCheckReducer from "./usercheck/userCheckReducer";
import userManagementReducer from "./userManagement/userReducer";
import userLogManagement from "./userLogManagement/userLogReducer";
import projectReducer from "./Project/projectReducer";
import holidayReducer from "./holidaymanagment/holidayReducer";
import { combineReducers } from "redux";
import tagReducer from "./tag/tagReducer";
import leaveReducer from "./leave/leaveReducer";
import clientReducer from "./clients/clientReducer";
import emplSalaryReducer from "./employeeSalary/emplSalaryReducer";
import extraStaffingReducers from "./extrastaffing/ExtraStaffingReducers";
import toggleReducer from "./toggle/toggleReducer";
import OrganizationReducer from "./organization/organizationReducer";
import projectCatalogueReducer from "./projectcatalogue/ProjectCatalogueReducer";

export default combineReducers({
  auth: authReducer,
  drawerData: drawerReducer,
  userCheckData: userCheckReducer,
  userManagement: userManagementReducer,
  leaveData: leaveReducer,
  clientData: clientReducer,
  userLogData: userLogManagement,
  holidayData: holidayReducer,
  projectData: projectReducer,
  tagReducer: tagReducer,
  toggleReducer: toggleReducer,
  extraStaffingData: extraStaffingReducers,
  emplSalaryData: emplSalaryReducer,
  OrganizationData:OrganizationReducer,
  projectCatalogueData: projectCatalogueReducer,
});
