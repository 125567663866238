import { GETONE_CATALOGUE_DATA, GET_ALL_PROJECT_CATALOGUE, LOADING } from "./ProjectCatalogueType";


const initialState: any = {
    loading: false,
    data: [],
};

export default function projectCatalogueReducer(state = initialState, action: any) {

    switch (action.type) {
        case GET_ALL_PROJECT_CATALOGUE:
            return {
                ...state,
                data: action.payload,
            }
        case GETONE_CATALOGUE_DATA:
            return {
                ...state,
                data: action.payload,
            }
        case LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state
    };
}