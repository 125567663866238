import {
  LOADING,
  LOGIN_USER,
  LOGOUT_USER,
  MODE_HANDLER,
  TOKEN_USER_DATA_HANDLER,
  OTPEMAIL,
} from "./authType";
import axios from "axios";

const initialState = {
  token: localStorage.getItem("token") || "",
  data: null,
  loading: false,
  // mode: localStorage.getItem("mode") || "dark",
  otpEmail: null,
};

if (initialState.token)
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + initialState.token;

export default function auth(state = initialState, action: any) {
  switch (action.type) {
    case LOGIN_USER:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("role", action.payload?.data?.role);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + action.payload.token;
      return {
        ...state,
        token: action.payload.token,
        role: action.payload.data.role,
        data: action.payload.data,
      };
    case TOKEN_USER_DATA_HANDLER:
      return {
        ...state,
        data: action.payload,
      };
    case LOGOUT_USER:
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      return {
        token: null,
        data: null,
      };
    // case MODE_HANDLER:
    //   localStorage.setItem("mode", action.payload);
    //   return {
    //     ...state,
    //     mode: action.payload,
    //   };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case OTPEMAIL:
      return {
        ...state,
        otpEmail: action.payload,
      };
    default:
      return state;
  }
}
