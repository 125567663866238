import { ReactNode, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { tokenUserData } from "../store/auth/authAction";
import { useDispatch } from "react-redux";
const AuthGuard = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    let checkStatus = accessToken ? true : false;
    if (checkStatus) {
      try {
        const decoded = jwtDecode(accessToken) as any;
        if (decoded?.exp) {
          const time = new Date().getTime();
          if (decoded?.exp * 1000 <= time) {
            localStorage.clear()
            return navigate("/");
          }
          dispatch(
            tokenUserData({
              token: accessToken,
            }))
        }
      } catch {
        localStorage.clear()
        return navigate("/");
      }
    }else{
      localStorage.clear()
      return navigate("/");
    }
  }, []);

  // const checkStatus = useMemo(() => localStorage.getItem("token") ? true : false, []);

  return <> {children} </>;
};
export default AuthGuard;
