import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const option = {
  autoClose: 1000,
};

const defaultHeaders = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

export function apiClient({
  url,
  data = {},
  method = "POST",
  headers = {},
  noHeaders,
  isToast,
  ...rest
}: any) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      headers: {
        ...(noHeaders ? {} : defaultHeaders),
        ...headers,
      },
      data,
      ...rest,
    })
      .then((res: any) => {
        resolve(res.data);
        if (isToast) toast.success(res?.data?.message, option);
      })
      .catch((err: any) => {
        if (err.response && err.response.data.message) {
          reject(err.response.data.error);
          if (err.response.data.statusCode === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("role");
            localStorage.removeItem("emailId");
            localStorage.removeItem("lastName");
            toast.error("Please Reload the Page and Login Again", option);

          } else {
            toast.error(err.response.data.message, option);
          }
        } else {
          reject(err);
        }
      });
  });
}
